<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanPiutangUsahaService from '@/services/LaporanPiutangUsahaService'
import PelangganService from '@/services/PelangganService'
import useVuelidate from '@vuelidate/core'
import SalesmanService from '@/services/SalesmanService'
import AreaService from '@/services/AreaService'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanPiutangUsahaService()
const pelangganService = new PelangganService()
const salesmanService = new SalesmanService()
const areaService = new AreaService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
let isLoadingPrint = ref(false)
let acPelanggan = ref([])
let list_salesman = ref([])
let acArea = ref([])
let form = reactive({
  date_to: null,
  pelanggan: null,
  salesman: null,
  area: null,
})
let submitted = ref(false)

const rules = {
  /*
  pelanggan: {
    required: helpers.withMessage('Pelanggan harus diisi.', required),
  },
  */
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await salesmanService.get('?sort=nama')
  list_salesman.value = res.data.data
})

function searchPelanggan(e) {
  pelangganService
    .get(`?filter[nama]=${e.query}`)
    .then((res) => {
      acPelanggan.value = res.data.data
    })
    .catch((err) => {
      errorHandler(err, 'Data Pelanggan', toast)
    })
}

function searchArea(e) {
  areaService
    .getBy(`?filter[nama]=${e.query}`)
    .then((res) => {
      acArea.value = res.data.data
    })
    .catch((err) => {
      errorHandler(err, 'Data Pelanggan', toast)
    })
}
/*
function selectPelanggan(e) {
  form.salesman = e.value.salesman
  form.area = e.value.area
}

function selectSalesman(e) {
  acArea = e.value.area
}
*/

/*
function onRequestData(isFormValid) {
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  const pelangganId = form.pelanggan

  submitted.value = true

  if (!isFormValid) {
    return
  }

  isLoading.value = true
  laporanService
    .getLaporanDetail(endDate, pelangganId)
    .then((res) => {
      items = res.data.data
      isLoading.value = false
    })
    .catch((err) => {
      errorHandler(err, 'Data Laporan Piutang Usaha', toast)
    })
    .finally(() => (isLoading.value = false))
}
*/

function onExport(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  isLoadingExport.value = true
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  let pelangganId = null
  let salesmanId = null
  let areaId = null
  if (form.pelanggan) {
    pelangganId = form.pelanggan.id
  }
  if (form.salesman) {
    salesmanId = form.salesman
  }
  if (form.area) {
    areaId = form.area.id
  }
  laporanService
    .exportLaporanDetail(endDate, pelangganId, salesmanId, areaId)
    .then((res) => {
      isLoadingExport.value = false
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'laporan-piutang-usaha-detail.xls')
      document.body.appendChild(link)
      link.click()
    })

    .catch((err) => {
      errorHandler(err, 'Data Laporan Piutang Usaha Detail', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onPrint(isFormValid) {
  submitted.value = true

  console.log('isFormValid', isFormValid)

  if (!isFormValid) {
    return
  }

  isLoadingPrint.value = true
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  let pelangganId = null
  let salesmanId = null
  let areaId = null
  if (form.pelanggan) {
    pelangganId = form.pelanggan.id
  }
  if (form.salesman) {
    salesmanId = form.salesman
  }
  if (form.area) {
    areaId = form.area.id
  }
  laporanService
    .printLaporanDetail(endDate, pelangganId, salesmanId, areaId)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-piutang-usaha-detail.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Laporan Piutang Usaha Detail', toast)
    })
    .finally(() => (isLoadingPrint.value = false))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  form.date_to = null
  form.pelanggan = null
  form.salesman = null
  form.area = null
}
</script>

<template>
  <fx-report-container
    title="Laporan Saldo Piutang Detail"
    :loading="isLoadingExport"
    @toggle="onToggle"
    @export="onExport"
  >
    <template #content>
      <form>
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-3">
            <!--
            <Dropdown
              v-model="form.pelanggan"
              :options="list_pelanggan"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Pelanggan"
              class="w-full"
              filter
              showClear
            />
            -->
            <AutoComplete
              v-model="form.pelanggan"
              :min-length="2"
              :suggestions="acPelanggan"
              field="nama"
              class="w-full"
              input-class="w-full"
              placeholder="Pelanggan"
              autofocus
              @complete="searchPelanggan($event)"
            >
              <template #item="slotProps">
                <div>
                  <strong
                    >{{ slotProps.item.kode }} -
                    {{ slotProps.item.nama }}</strong
                  >
                </div>
                <div>Alamat: {{ slotProps.item.alamat }}</div>
                <div>Salesman: {{ slotProps.item.salesman.nama }}</div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-4">
            <Button
              :loading="isLoadingPrint"
              type="button"
              icon="pi pi-file-pdf"
              label="Cetak"
              class="mr-2"
              @click="onPrint(!v$.$invalid)"
            />
            <Button
              :loading="isLoadingExport"
              type="button"
              icon="pi pi-file-excel"
              label="Export"
              class="p-button-success mr-2"
              @click="onExport(!v$.$invalid)"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
          <div class="field col-12 md:col-4">
            <Dropdown
              v-model="form.salesman"
              :options="list_salesman"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Salesman"
              class="w-full"
              :show-clear="true"
            />
          </div>
          <div class="field col-12 md:col-2">
            <AutoComplete
              v-model="form.area"
              :min-length="2"
              :suggestions="acArea"
              field="nama"
              class="w-full"
              input-class="w-full"
              placeholder="Area"
              autofocus
              @complete="searchArea($event)"
            >
            </AutoComplete>
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
